<template>
  <nav>
    <div class="nav-links">
      <div class="nav-link-wrap">
        <router-link to="/">Home</router-link>
      </div>
      <div class="nav-link-wrap">
        <router-link to="/characters">Character</router-link>
      </div>
      <div class="nav-link-wrap">
        <router-link to="/imageSearch">Image Search</router-link>
      </div>
      <div class="nav-link-wrap">
        <router-link to="/about">About</router-link>
      </div>
      <div class="nav-link-wrap">
        <router-link to="/contact">Contact</router-link>
      </div>
      <div class="nav-link-wrap">
        <router-link to="/favorites" class="nav-link">Favorites</router-link>
      </div>
    </div>
  </nav>
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #C69749;
}

nav {
  height: 1vh;
  width: 45vw;
  margin: 0 auto;
  padding: 30px;
  display: flex;
  grid-template-columns: auto 1fr;
  align-items: center;
  background-color: transparent;
  justify-content: center;
}



nav div {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  background-color: transparent;
  border-radius: 5px;
  border: 2px solid transparent;
}

nav .nav-links {
  width: 85vw;
  height: min-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(17, 17, 18, .65);
  border-radius: 12px;
  border: 2px solid transparent;
  margin: 0 auto;
  margin-top: 4vh;
  box-shadow: 0px 0px 75px #000000;
}


nav .nav-link-wrap{
  margin: .5vh 1vw;
  padding: 1vh;
  background-color: #282A3A;
  border-radius: 30px;
  border: 2px solid transparent;
}

nav .nav-link-wrap:hover {
  border: 2px solid #C69749;
  transition: border 100ms ease-in-out;
}

nav a {
  font-weight: bold;
  color: #735F32;
  padding: 1vh;
  text-decoration: none;
}

nav a.router-link-exact-active {
  color: #C69749;
}


@media only screen and (max-width: 767px) {
  nav {
    display: flex;
    justify-content: center;
    margin-top: 5vh;
  }

  nav div {
    display: flex;
    padding: 1vh 1vh 1vh 1vh;
    height: 4vh;
    width: 80vw;
    border-radius: 30px;
  }

  nav div:hover {
    border: 2px solid transparent;
  }

  nav a:hover {
    color: #735F32;
  }

  nav a.router-link-exact-active {
  color: #C69749;
}

nav .nav-link-wrap:hover {
  border: 2px solid transparent;
}

nav .nav-links {
  width: 90vw;
  height: 6vh;
  display: inline-flexbox;
  justify-content: center;
  align-items: center;
  background-color: #282A3A;
  border-radius: 30px;
  border: 2px solid transparent;
  font-size: 12px;
  padding-top: 0;
  padding-bottom: 0;
}

nav .nav-link-wrap{
  width: 25vw;
  display: flex;
  margin: 0 auto;
  background-color: transparent;
  border-radius: 30px;
  border: 2px solid transparent;
}


  #wrapperHome {
    height: 75vh;
    width: 80vw;
    margin-top: 5vh;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
