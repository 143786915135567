<template>
    <div id="charPage">
      <h1>{{ nikkeName }}</h1>
  
      <!-- Rating Checkboxes -->
      <div class="checkbox-wrapper-2">
        <label for="g">
          <input
            id="g"
            type="checkbox"
            class="sc-gJwTLC ikxBAC"
            v-model="checkedValues"
            :value="'g'"
            @change="ratingCheck"
            checked
          />
          General
        </label>
        <label for="s">
          <input
            id="s"
            type="checkbox"
            class="sc-gJwTLC ikxBAC"
            v-model="checkedValues"
            :value="'s'"
            @change="ratingCheck"
          />
          Sensitive
        </label>
        <label for="q">
          <input
            id="q"
            type="checkbox"
            class="sc-gJwTLC ikxBAC"
            v-model="checkedValues"
            :value="'q'"
            @change="ratingCheck"
          />
          Questionable
        </label>
        <label for="e">
          <input
            id="e"
            type="checkbox"
            class="sc-gJwTLC ikxBAC"
            v-model="checkedValues"
            :value="'e'"
            @change="ratingCheck"
          />
          Explicit
        </label>
      </div>
  
      <div id="wrapper">
        <div class="nikke-page">
          <template v-for="(img, index) in images" :key="index">
            <div class="media-container">
              <!-- If it's a video -->
              <template v-if="typeof img === 'string' && (img.endsWith('.webm') || img.endsWith('.mp4'))">
                <video
                  :src="img"
                  controls
                  @click="openModal(img, index)"
                ></video>
              </template>
  
              <!-- Otherwise, it's an image -->
              <template v-else>
                <img
                  :src="img"
                  :alt="img"
                  @click="openModal(img, index)"
                />
              </template>
  
              <!-- Floating favorite button on each thumbnail -->
              <button class="favorite-button" @click.stop="toggleFavorite(img)">
                {{ isFavorited(img) ? '❤️' : '🤍' }}
              </button>
            </div>
          </template>
        </div>
      </div>
  
      <!-- Modal Component -->
      <div v-if="showModal" class="modal">
        <div class="modal-content">
          <!-- Close button -->
          <span @click="closeModal" class="close">&times;</span>
  
          <!-- Favorite button in modal (top-left or wherever you like) -->
          <button
            class="favorite-button-modal"
            @click.stop="toggleFavorite(images[selectedImageIndex])"
          >
            {{ isFavorited(images[selectedImageIndex]) ? '❤️' : '🤍' }}
          </button>
  
          <!-- Arrows -->
          <div class="arrows">
            <img src="@/assets/left.png" @click="prevImage" />
            <img src="@/assets/right.png" @click="nextImage" />
          </div>
  
          <!-- Display video if it's mp4/webm, otherwise image -->
          <template v-if="selectedIsVideo">
            <video
              :src="selectedImage"
              controls
              autoplay
              class="modal-video"
            ></video>
          </template>
          <template v-else>
            <img
              :src="selectedImage"
              alt="selectedImage"
            />
          </template>
  
          <!-- Optional info section -->
          <p>Artist: {{ imageTags[selectedImageIndex] }}</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        // Name from route params
        nikkeName: this.$route.params.name,
  
        // Image / video array and artist tags
        images: [],
        imageTags: [],
  
        // Favorites
        favorites: [],
  
        // Modal state
        showModal: false,
        selectedImage: "",
        selectedImageIndex: 0,
        selectedIsVideo: false,
  
        // Ratings
        checkedValues: ["g"],
        selectedValues: ["g"],
  
        // Pagination & infinite scroll
        page: 1,
        canCall: true
      };
    },
  
    mounted() {
      // Load favorites from localStorage
      this.favorites = JSON.parse(localStorage.getItem("favorites")) || [];
  
      // Initial load
      this.getImages();
      this.loadMore();
    },
  
    methods: {
      //--------------------------------------------------
      // FAVORITES
      //--------------------------------------------------
      toggleFavorite(item) {
        // 'item' can be the image URL or a post ID—here it's the URL
        const index = this.favorites.indexOf(item);
        if (index === -1) {
          this.favorites.push(item);
        } else {
          this.favorites.splice(index, 1);
        }
        localStorage.setItem("favorites", JSON.stringify(this.favorites));
      },
  
      isFavorited(item) {
        return this.favorites.includes(item);
      },
  
      //--------------------------------------------------
      // MODAL
      //--------------------------------------------------
      openModal(img, index) {
        this.showModal = true;
        this.selectedImage = img;
        this.selectedImageIndex = index;
        this.selectedIsVideo = img.endsWith(".mp4") || img.endsWith(".webm");
      },
  
      closeModal() {
        this.showModal = false;
      },
  
      prevImage() {
        if (this.selectedImageIndex > 0) {
          this.selectedImageIndex -= 1;
          this.selectedImage = this.images[this.selectedImageIndex];
          this.selectedIsVideo = this.selectedImage.endsWith(".mp4") || this.selectedImage.endsWith(".webm");
        }
      },
  
      nextImage() {
        if (this.selectedImageIndex < this.images.length - 1) {
          this.selectedImageIndex += 1;
          this.selectedImage = this.images[this.selectedImageIndex];
          this.selectedIsVideo = this.selectedImage.endsWith(".mp4") || this.selectedImage.endsWith(".webm");
        } else {
          // Load more if we reach the end
          this.getImages();
        }
      },
  
      //--------------------------------------------------
      // CHECKBOX RATING
      //--------------------------------------------------
      async ratingCheck() {
        this.selectedValues = this.checkedValues.join(",");
        // Reset images + tags
        this.page = 1;
        this.images = [];
        this.imageTags = [];
        this.getImages();
      },
  
      //--------------------------------------------------
      // INFINITE SCROLL
      //--------------------------------------------------
      loadMore() {
        window.addEventListener("scroll", () => {
          let bottomOfWindow =
            document.documentElement.scrollTop + window.innerHeight >
            document.documentElement.offsetHeight - 100;
  
          if (bottomOfWindow && this.canCall) {
            this.canCall = false;
            this.getImages();
            setTimeout(() => {
              this.canCall = true;
            }, 100);
          }
        });
      },
  
      //--------------------------------------------------
      // GET IMAGES
      //--------------------------------------------------
      convertName() {
        const nikkeMapping = {
        "Anne: Miracle Fairy": "n102_(miracle_fairy)",
        "Rupee: Winter Shopper": "rupee_(winter_shopper)",
        "Snow White": "snow_white",
        "2B": "2b_(nier:automata)",
        "A2": "a2_(nier:automata)",
        "Pascal": "pascal_(nier:automata)",
        "Rei Ayanami": "ayanami_rei",
        "Asuka": "souryuu_asuka_langley",
        "Misato": "katsuragi_misato",
        "Mari": "makinami_mari_illustrious",
        "Ram": "ram_(re:zero)",
        "Rem": "rem_(re:zero)",
        "Emilia": "emilia_(re:zero)",
        "Alice: Wonderland Bunny": "alice_(wonderland_bunny)",
        "Anchor: Innocent Maid": "anchor_(innocent_maid)",
        "Anis: Sparkling Summer": "anis_(sparkling_summer)",
        "D: Killer Wife": "d_(killer_wife)",
        "Guillotine: Winter Slayer": "guillotine_(winter_slayer)",
        "Helm: Aquamarine": "helm_(aqua_marine)_(nikke)", // ✅ FIXED
        "Neon: Blue Ocean": "neon_(blue_ocean)",
        "Ludmilla: Winter Owner": "ludmilla_(winter_owner)",
        "Maiden: Ice Rose": "maiden_(ice_rose)",
        "Mary: Bay Goddess": "mary_(bay_goddess)",
        "Mast: Romantic Maid": "mast_(romantic_maid)",
        "Mica: Snow Buddy": "mica_(snow_buddy)",
        "Privaty: Unkind Maid": "privaty_(unkind_maid)",
        "Quency: Escape Queen": "quency_(escape_queen)",
        "Rapi: Red Hood": "rapi_(red_hood)",
        "Rapunzel: Pure Grace": "rapunzel_(pure_grace)",
        "Red Hood": "red_hood",
        "Rosanna: Chic Ocean": "rosanna_(chic_ocean)",
        "Sakura: Bloom in Summer": "sakura_(bloom_in_summer)",
        "Scarlet: Black Shadow": "scarlet_(black_shadow)",
        "Snow White: Innocent Days": "snow_white_(innocent_days)"
    };
  
        // Return the mapped value or a default
        return (
          nikkeMapping[this.nikkeName] ||
          this.nikkeName.toLowerCase().replace(/:|\s/g, "_")
        );
      },
  
      async getImages() {
        let characterName = this.convertName();
        let tags = "";
  
        // Example logic for certain character sets
        if (["himeno", "makima", "power"].includes(characterName)) {
          tags = characterName + "_(chainsaw_man) rating:" + this.selectedValues;
        } else if (
          [
            "2b_(nier:automata)",
            "a2_(nier:automata)",
            "pascal_(nier:automata)",
          ].includes(characterName)
        ) {
          tags = characterName + " rating:" + this.selectedValues;
        } else if (
          ["ram_(re:zero)", "rem_(re:zero)", "emilia_(re:zero)"].includes(
            characterName
          )
        ) {
          tags = characterName + " rating:" + this.selectedValues;
        } else if (
          [
            "souryuu_asuka_langley",
            "makinami_mari_illustrious",
            "katsuragi_misato",
          ].includes(characterName)
        ) {
          tags = characterName + " rating:" + this.selectedValues;
        } else {
          // Default NIKKE naming pattern
          tags = characterName + "_(nikke) rating:" + this.selectedValues;
        }
  
        const url = "https://danbooru.donmai.us/posts.json";
  
        axios
          .get(url, {
            params: {
              tags,
              limit: 20,
              page: this.page++,
            },
            headers: { "Content-Type": "application/json" },
          })
          .then((response) => {
            const responseData = response.data.filter((post) => post.file_url != null);
            // Add file_urls to images array; tags to imageTags
            this.images = this.images.concat(responseData.map((post) => post.file_url));
            this.imageTags = this.imageTags.concat(responseData.map((post) => post.tag_string_artist));
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
  };
  </script>
  



<style>
/* Position the left arrow icon */
.arrows img:first-child {
    position: absolute;
    left: -1vw;
    /* Adjust the left position to your preference */
    top: 50%;
    transform: translateY(35vh);
    opacity: .3;
    width: 13vw;
    height: 13vh;
}

/* Position the right arrow icon */
.arrows img:last-child {
    position: absolute;
    right: -1vw;
    /* Adjust the right position to your preference */
    top: 50%;
    transform: translateY(35vh);
    opacity: .3;
    width: 13vw;
    height: 13vh;
}


button {
    padding: 10px 20px;
    border-radius: 5px;
    margin: 10px 0;
}

/* checbox styling */
label {
    display: inline-block;
    margin: 0 .5vw;
    background-color: #282A3A;
    border-radius: 50px;
    padding: 5px 8px 10px;
    white-space: nowrap;
}

input {
    top: 5px;
}

.checkbox-wrapper-2 .ikxBAC {
    appearance: none;
    background-color: #63646e;
    border-radius: 72px;
    border-style: none;
    flex-shrink: 0;
    height: 20px;
    margin: 0;
    position: relative;
    width: 30px;
}

.checkbox-wrapper-2 .ikxBAC::before {
    bottom: -6px;
    content: "";
    left: -6px;
    position: absolute;
    right: -6px;
    top: -6px;
}

.checkbox-wrapper-2 .ikxBAC,
.checkbox-wrapper-2 .ikxBAC::after {
    transition: all 100ms ease-out;
}

.checkbox-wrapper-2 .ikxBAC::after {
    background-color: #282A3A;
    border-radius: 50%;
    content: "";
    height: 14px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 14px;
}

.checkbox-wrapper-2 input[type=checkbox] {
    cursor: default;
}

.checkbox-wrapper-2 .ikxBAC:checked {
    background-color: #C69749;
}

.checkbox-wrapper-2 .ikxBAC:checked::after {
    background-color: #282A3A;
    left: 13px;
}

.checkbox-wrapper-2 :focus:not(.focus-visible) {
    outline: 0;
}

#charPage {
    margin: 0 auto;
    margin-top: 5vh;
}

.h1 {
    margin: 10vh 10vw;
    text-align: center;
}

/* set the parent container to display as a grid */
.nikke-page {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    /* 3 columns */
    grid-gap: 10px;
    /* gap of 10px between images */
}

/* set the images to have a fixed width and height */
.nikke-page img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    object-fit: cover;
}

.nikke-page video {
    width: 100%;
    height: auto;
    border-radius: 15px;
}

/* Modal Component */
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
    background-color: #282A3A;
    width: 95%;
    height: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    border: 2px solid #C69749;
}

.modal-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.close {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 42px;
    font-weight: bold;
    color: #ff0000;
    cursor: pointer;
}

.info {
    margin-top: 10px;
    display: none;
}

.modal img {
    width: 95%;
    height: 95%;
    object-fit: scale-down;
    inherits: border-radius;
}

.modal-content {
    padding-top: 2vh;
}


@media only screen and (max-width: 767px) {
    .modal {
        width: 95vw;
        height: 95vh;
    }

    label {
        display: inline-flexbox;
        font-size: 9px;
        margin: 0 .5vw;
        padding: 2px 8px 12px;
        white-space: nowrap;
        max-width: 40vw;
    }

    input {
        top: 6px;
    }

    .arrows img:first-child {
        position: absolute;
        left: -1vw;
        /* Adjust the left position to your preference */
        top: 50%;
        transform: translateY(35vh);
        opacity: .6;
        width: 13vw;
        height: 13vh;
    }

    /* Position the right arrow icon */
    .arrows img:last-child {
        position: absolute;
        right: -1vw;
        /* Adjust the right position to your preference */
        top: 50%;
        transform: translateY(35vh);
        opacity: .6;
        width: 13vw;
        height: 13vh;
    }

}

.modal-video {
    width: 85%;
    height: 85%;
    object-fit: contain;
    border-radius: 15px;
}

.favorite-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(0, 0, 0, 0.5);
    color: gold;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 18px;
    cursor: pointer;
}

.favorite-btn:hover {
    background: rgba(255, 255, 255, 0.5);
}

.media-container {
    position: relative;
    display: inline-block;
}
.favorite-button-modal {
  position: absolute;
  top: 0;
  left: 10px;
  font-size: 42px;
  font-weight: bold;
  color: #ff0000;
  cursor: pointer;
  background: transparent;
  border: none;
  z-index: 2;
}

/* Reuse or copy the same style for the floating favorite button in the gallery */
.favorite-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  padding: 5px;
  z-index: 1;
}

.media-container {
  position: relative;
}
</style>
