<!-- FavoritesPage.vue -->
<template>
    <div id="charPage">
        <div id="wrapper">
            <h1>Favorites</h1>
            <div class="nikke-page">
                <template v-for="(imgObj, index) in images" :key="imgObj.id">
                    <div class="media-container">
                        <template v-if="imgObj.url.endsWith('.webm') || imgObj.url.endsWith('.mp4')">
                            <video :src="imgObj.url" controls></video>
                        </template>
                        <template v-else>
                            <img :src="imgObj.url" :alt="imgObj.url" @click="openModal(imgObj.url, index)">
                        </template>
                        <button class="favorite-button" @click.stop="toggleFavorite(imgObj.id)">
                            {{ isFavorited(imgObj.id) ? '❤️' : '🤍' }}
                        </button>
                    </div>
                </template>
                <div v-if="images.length === 0" class="empty-state">
                    No favorites yet. Start adding some from the search page!
                </div>
            </div>
        </div>

        <!-- Reuse the same modal component -->
        <div v-if="showModal" class="modal">
            <div class="modal-content">
                <span @click="closeModal" class="close">&times;</span>
                <div class="arrows">
                    <img src="@/assets/left.png" @click="prevImage">
                    <img src="@/assets/right.png" @click="nextImage">
                </div>
                <template v-if="selectedIsVideo">
                    <video :src="selectedImage" controls autoplay muted playsinline class="modal-video"></video>
                </template>
                <template v-else>
                    <img :src="selectedImage" alt="selectedImage">
                </template>
                <p>Artist: {{ images[selectedImageIndex]?.tags }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            images: [],
            showModal: false,
            selectedImage: "",
            selectedImageIndex: 0,
            selectedIsVideo: false,
            favorites: []
        }
    },
    mounted() {
        this.loadFavorites();
    },
    methods: {
        async loadFavorites() {
            this.favorites = JSON.parse(localStorage.getItem('favorites')) || [];
            if (this.favorites.length === 0) return;

            try {
                const response = await axios.get('https://danbooru.donmai.us/posts.json', {
                    params: {
                        tags: `id:${this.favorites.join(',')}`,
                        limit: 200
                    }
                });
                
                const responseData = response.data.filter(post => 
                    post.file_url && !post.file_url.endsWith('.zip')
                );
                
                this.images = responseData.map(post => ({
                    id: post.id,
                    url: post.file_url,
                    tags: post.tag_string_artist
                }));
            } catch (error) {
                console.error("Error loading favorites:", error);
            }
        },
        toggleFavorite(id) {
            const index = this.favorites.indexOf(id);
            if (index === -1) {
                this.favorites.push(id);
            } else {
                this.favorites.splice(index, 1);
                // Remove from displayed images immediately
                this.images = this.images.filter(img => img.id !== id);
            }
            localStorage.setItem('favorites', JSON.stringify(this.favorites));
        },
        isFavorited(id) {
            return this.favorites.includes(id);
        },
        openModal(img, index) {
            this.showModal = true;
            this.selectedImage = img;
            this.selectedImageIndex = index;
            this.selectedIsVideo = img.endsWith(".mp4") || img.endsWith(".webm");
        },
        closeModal() {
            this.showModal = false;
        },
        prevImage() {
            if (this.selectedImageIndex > 0) {
                this.selectedImageIndex -= 1;
                this.selectedImage = this.images[this.selectedImageIndex].url;
                this.selectedIsVideo = this.selectedImage.endsWith(".mp4") || this.selectedImage.endsWith(".webm");
            }
        },
        nextImage() {
            if (this.selectedImageIndex < this.images.length - 1) {
                this.selectedImageIndex += 1;
                this.selectedImage = this.images[this.selectedImageIndex].url;
                this.selectedIsVideo = this.selectedImage.endsWith(".mp4") || this.selectedImage.endsWith(".webm");
            }
        }
    }
}
</script>

<style scoped>
.empty-state {
    color: #C69749;
    font-size: 1.2rem;
    text-align: center;
    width: 100%;
    padding: 2rem;
}
.media-container {
    position: relative;
}

.favorite-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    padding: 5px;
    z-index: 1;
}

.searchBar {
    width: 40vw;
    border: 3px solid #C69749;
    padding: 5px;
    height: 3vh;
    border-radius: 15px;
    outline: none;
    color: #C69749;
    background-color: #282A3A;
    font-size: 1rem;
}

#searchcombo {
    width: 42vw;
    margin: 0 auto;
    background-color: rgba(17, 17, 18, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 15px;
}

.searchButton {
    background-color: #282A3A;
    color: #C69749;
    border: none;
    padding: 0 10px;
    cursor: pointer;
    height: 3.2vh;
    width: fit-content;
    margin-left: .5vw;
    font-size: 1rem;
}

.searchButton:hover {
    background-color: #C69749;
    color: rgba(17, 17, 18, 1);
}

#searchcombo form {
    margin-bottom: -2vh;
}

#con {
    position: absolute;
    width: fit-content;
    background-color: rgba(17, 17, 18, 1);
    z-index: 1;
    margin: 0 auto;
    display: block;
    margin-top: -2vh;
}

li {
    margin: 0 auto;
    max-width: min-content;
    margin: 4px;
    padding: 4px;
    list-style-type: none;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    background-color: #282A3A;
    border-radius: 15px;
}

li:hover {
    background-color: #f1f1f1;
}

li span {
    font-size: 14px;
    color: #C69749;
    border-radius: 15px;
}

li small {
    font-size: 12px;
    color: #808080;
}

.arrows img:first-child {
    position: absolute;
    left: -1vw;
    top: 50%;
    transform: translateY(35vh);
    opacity: .3;
    width: 13vw;
    height: 13vh;
}

.arrows img:last-child {
    position: absolute;
    right: -1vw;
    top: 50%;
    transform: translateY(35vh);
    opacity: .3;
    width: 13vw;
    height: 13vh;
}

button {
    padding: 10px 20px;
    border-radius: 5px;
    margin: 10px 0;
}

label {
    display: inline-block;
    margin: 0 .5vw;
    background-color: #282A3A;
    border-radius: 50px;
    padding: 5px 8px 10px;
    white-space: nowrap;
}

input {
    top: 5px;
}

.checkbox-wrapper-2 {
    margin-top: 1vh;
    margin-bottom: -2vh;
}

.checkbox-wrapper-2 .ikxBAC {
    appearance: none;
    background-color: #63646e;
    border-radius: 72px;
    border-style: none;
    flex-shrink: 0;
    height: 20px;
    margin: 0;
    position: relative;
    width: 30px;
}

.checkbox-wrapper-2 .ikxBAC::before {
    bottom: -6px;
    content: "";
    left: -6px;
    position: absolute;
    right: -6px;
    top: -6px;
}

.checkbox-wrapper-2 .ikxBAC,
.checkbox-wrapper-2 .ikxBAC::after {
    transition: all 100ms ease-out;
}

.checkbox-wrapper-2 .ikxBAC::after {
    background-color: #282A3A;
    border-radius: 50%;
    content: "";
    height: 14px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 14px;
}

.checkbox-wrapper-2 input[type=checkbox] {
    cursor: default;
}

.checkbox-wrapper-2 .ikxBAC:checked {
    background-color: #C69749;
}

.checkbox-wrapper-2 .ikxBAC:checked::after {
    background-color: #282A3A;
    left: 13px;
}

.checkbox-wrapper-2 :focus:not(.focus-visible) {
    outline: 0;
}

#charPage {
    margin: 0 auto;
    margin-top: 5vh;
}

.h1 {
    margin: 10vh 10vw;
    text-align: center;
}

.nikke-page {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-gap: 10px;
}

.nikke-page img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    object-fit: cover;
}

.nikke-page video {
    width: 100%;
    height: auto;
    border-radius: 15px;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
    background-color: #282A3A;
    width: 95%;
    height: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    border: 2px solid #C69749;
}

.modal-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.close {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 42px;
    font-weight: bold;
    color: #ff0000;
    cursor: pointer;
}

.info {
    margin-top: 10px;
    display: none;
}

.modal img {
    width: 95%;
    height: 95%;
    object-fit: scale-down;
    inherits: border-radius;
}

.modal-content {
    padding-top: 2vh;
}

@media only screen and (max-width: 767px) {
    .searchBar {
        width: 75vw;
        height: 4vh;
    }

    #searchcombo {
        width: 80vw;
    }

    .searchButton {
        height: 4.5vh;
    }

    .modal {
        width: 95vw;
        height: 95vh;
    }

    label {
        display: inline-flexbox;
        font-size: 9px;
        margin: 0 .5vw;
        padding: 2px 8px 12px;
        white-space: nowrap;
        max-width: 40vw;
    }

    input {
        top: 6px;
    }

    .arrows img:first-child {
        position: absolute;
        left: -1vw;
        top: 50%;
        transform: translateY(35vh);
        opacity: .6;
        width: 13vw;
        height: 13vh;
    }

    .arrows img:last-child {
        position: absolute;
        right: -1vw;
        top: 50%;
        transform: translateY(35vh);
        opacity: .6;
        width: 13vw;
        height: 13vh;
    }

    .searchButton {
        height: 4.5vh;
    }
}

.modal-video {
    width: 85%;
    height: 85%;
    object-fit: contain;
    border-radius: 15px;
}
/* Reuse styles from SearchPage.vue */
</style>