<template>
    <div id="wrapperCO">
        <h1>Contact Us</h1>
        <p>Thank you for visiting Nikke Viewer!</p> 
        <p>If you have any questions or comments, please feel free to reach out to us.</p>
        <p>You can contact me by emailing : <a href="mailito: nikke-viewer-support@googlegroups.com">nikke-viewer-support@googlegroups.com</a> </p>
        <p>Feel free to submit bug reports or feature suggestions to the email.</p>
        <p>Thank you for visiting Nikke Viewer if you want to donate to keep the site ad free and to support the upkeep and 
            development of the website you can do so at my <a href="https://ko-fi.com/yureiryu" target="_blank">Ko-Fi</a> page.
        </p>
    </div>
</template>

<script>
export default {
    name: "ContactPage",
};
</script>

<style>
h1 {
    font-size: 2em;
    text-align: center;
    margin-top: 5vh;
    padding-top: 2vh;
}

p {
    text-align: center;
    padding: 0 2vw;
}

a {
    color: #fff;
    text-decoration: underline;
}

#wrapperCO {
    height: fit-content;
    width: 65vw;
    margin: 0 auto;
    margin-top: 5vh;
    background-color: #282A3A;
    border-radius: 15px;
    box-shadow: 0px 0px 75px #000000;
    padding-bottom: 2vh;
}

@media screen and (max-width: 767px) {
    p {
        text-align: center;
        padding: 0 20vw;
    }

    #wrapperCO {
        height: fit-content;
        width: 90vw;
        margin: 0 auto;
        margin-top: 5vh;
        background-color: #282A3A;
        border-radius: 15px;
    }
}
</style>
