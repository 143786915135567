import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NikkeList from '@/components/NikkeList'
import NikkePage from '@/components/NikkePage'
import AboutPage from '@/components/AboutPage'
import ContactPage from '@/components/ContactPage'
import SearchPage from '@/components/SearchPage'
import FavoritesPage from '@/components/FavoritesPage.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/characters',
    name: 'Character',
    component: NikkeList
  }, 
  {
    path: '/nikke/:name',
    name: 'NikkePage',
    component: NikkePage
  },
  {
    path: '/imageSearch',
    name: 'SearchPage',
    component: SearchPage
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: AboutPage
  },
  {
    path: '/contact',
    name: 'ContactPage',
    component: ContactPage
  },
  {
    path: '/favorites',
    name: 'Favorites',
    component: FavoritesPage
},
  {
    path: "/:catchAll(.*)",
    redirect: "/"
},
]

const router = createRouter({
  history: createWebHistory(),
  routes
})


export default router
