<template>
    <div>
        <div id="wrapper">
            <input class="searchBar" type="text" v-model="search" placeholder="Search for Nikkes...">
            <div class="nikkes">
                <router-link v-for="nikke in filteredNikkes" :key="nikke.name" :to="`/nikke/${nikke.name}`">
                    <a>
                        <div class="nikke">
                            <div class="image" :style="{ backgroundImage: `url(${nikke.image})` }"></div>
                            <div class="name">{{ nikke.name }}</div>
                        </div>
                    </a>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            nikkes: [
                { name: "2B" },
                { name: "A2" },
                { name: "Ade" },
                { name: "Admi" },
                { name: "Alice" },
                { name: "Alice: Wonderland Bunny" },
                { name: "Anchor" },
                { name: "Anchor: Innocent Maid" },
                { name: "Anis" },
                { name: "Anis: Sparkling Summer" },
                { name: "Anne: Miracle Fairy" },
                { name: "Aria" },
                { name: "Asuka" },
                { name: "Bay" },
                { name: "Biscut" },
                { name: "Blanc" },
                { name: "Belorta" },
                { name: "Brid" },
                { name: "Centi" },
                { name: "Cinderella" },
                { name: "Clay" },
                { name: "Cocoa" },
                { name: "Crow" },
                { name: "Crown" },
                { name: "D" },
                { name: "D: Killer Wife" },
                { name: "Delta" },
                { name: "Diesel" },
                { name: "Dolla" },
                { name: "Dorothy" },
                { name: "Drake" },
                { name: "Ein" },
                { name: "Elegg" },
                { name: "Emilia" },
                { name: "Emma" },
                { name: "Epinel" },
                { name: "Ether" },
                { name: "Eunhwa" },
                { name: "Exia" },
                { name: "Flora" },
                { name: "Folkwang" },
                { name: "Frima" },
                { name: "Grave" },
                { name: "Guillotine" },
                { name: "Guillotine: Winter Slayer" },
                { name: "Guilty" },
                { name: "Harran" },
                { name: "Helm" },
                { name: "Helm: Aquamarine" },
                { name: "Himeno" },
                { name: "Isabel" },
                { name: "Jackal" },
                { name: "Julia" },
                { name: "Kilo" },
                { name: "Laplace" },
                { name: "Leona" },
                { name: "Liter" },
                { name: "Ludmilla" },
                { name: "Ludmilla: Winter Owner" },
                { name: "Maiden" },
                { name: "Maiden: Ice Rose" },
                { name: "Makima" },
                { name: "Mana" },
                { name: "Marciana" },
                { name: "Mari" },
                { name: "Marian" },
                { name: "Mary" },
                { name: "Mary: Bay Goddess" },
                { name: "Mast" },
                { name: "Mast: Romantic Maid" },
                { name: "Maxwell" },
                { name: "Mica" },
                { name: "Mica: Snow Buddy" },
                { name: "Mihara" },
                { name: "Milk" },
                { name: "Miranda" },
                { name: "Misato" },
                { name: "Modernia" },
                { name: "N102" },
                { name: "Neon" },
                { name: "Neon: Blue Ocean" },
                { name: "Neve" },
                { name: "Moran" },
                { name: "Naga" },
                { name: "Nero" },
                { name: "Nihilister" },
                { name: "Noah" },
                { name: "Noir" },
                { name: "Noise" },
                { name: "Novel" },
                { name: "Pascal" },
                { name: "Pepper" },
                { name: "Phantom" },
                { name: "Poli" },
                { name: "Power" },
                { name: "Privaty" },
                { name: "Privaty: Unkind Maid" },
                { name: "Quency" },
                { name: "Quency: Escape Queen" },
                { name: "Quiry" },
                { name: "Ram" },
                { name: "Rapi" },
                { name: "Rapi: Red Hood" },
                { name: "Rapunzel" },
                { name: "Rapunzel: Pure Grace" },
                { name: "Red Hood" },
                { name: "Rei Ayanami" },
                { name: "Rem" },
                { name: "Rosanna" },
                { name: "Rosanna: Chic Ocean" },
                { name: "Rouge" },
                { name: "Rumani" },
                { name: "Rupee" },
                { name: "Rupee: Winter Shopper" },
                { name: "Sakura" },
                { name: "Sakura: Bloom in Summer" },
                { name: "Scarlet" },
                { name: "Scarlet: Black Shadow" },
                { name: "Signal" },
                { name: "Sin" },
                { name: "Snow White" },
                { name: "Snow White: Innocent Days" },
                { name: "Soda" },
                { name: "Soda: Twinkling Bunny" },
                { name: "Soline" },
                { name: "Sugar" },
                { name: "Tia" },
                { name: "Trove" },
                { name: "Trony" },
                { name: "Vesti" },
                { name: "Viper" },
                { name: "Volume" },
                { name: "Yan" },
                { name: "Yulha" },
                { name: "Yuni" },
                { name: "Zwei" }
            ],
            search: '',
        }
    },
    computed: {
        filteredNikkes() {
            return this.nikkes.filter(nikke => {
                return nikke.name.toLowerCase().includes(this.search.toLowerCase());
            });
        }
    },
    mounted() {
        this.assignNikkeAttributes();
    },
    methods: {
        assignNikkeAttributes() {
            this.nikkes.forEach(nikke => {
                nikke.image = `./img/${'_' + (nikke.name).replace(/:|\s/g, '').toLowerCase()}.webp`;
            });
        }
    }
};
</script>

<style>
/* Center everything */
#wrapper {
    text-align: center;
    padding: 20px;
}

/* Search Bar */
.searchBar {
    width: 40%;
    border: 3px solid #C69749;
    padding: 8px;
    font-size: 16px;
    height: 30px;
    border-radius: 20px;
    outline: none;
    color: #C69749;
    margin-bottom: 20px;
    background-color: #282A3A;
}

/* Grid Layout */
.nikkes {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 20px;
    justify-content: center;
    align-items: center;
    max-width: 800px;
    margin: auto;
}

/* Nikke Box */
.nikke {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #1E1F2A;
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease-in-out;
    width: 120px;
    min-height: 150px; /* Ensure uniform size */
}

.nikke:hover {
    transform: scale(1.1);
}

/* Circular Image */
.image-container {
    width: 80px;  /* Fixed width */
    height: 80px; /* Fixed height */
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid #C69749;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0; /* Prevents stretching */
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image maintains correct proportions */
    background-size: cover;
    background-position: center;
    border-radius: 50%;
}

/* Name Styling */
.name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
    text-align: center;
}

/* Responsive Design */
@media only screen and (max-width: 767px) {
    .searchBar {
        width: 80%;
    }

    .nikkes {
        grid-template-columns: repeat(2, minmax(120px, 1fr)); /* Exactly 2 per row */
        column-gap: 0.5px; /* Reduce horizontal gap */
        row-gap: 5px; /* Keep rows slightly spaced */
    }
    .nikke {
        width: 100px;
    }

    .image-container {
        width: 70px;
        height: 70px;
    }

    .name {
        font-size: 12px;
    }
}
</style>