<template>
    <div id="wrapperAB">
        <h1>About Nikke Viewer</h1>
        <p>Nikke Viewer is a platform dedicated to showcasing the images of your favorite Nikkes.</p>
        <p>The site uses danbooru tags so you can search for any of the tags on danbooru not limited to characters.</p>
        <a href="https://danbooru.donmai.us/tags?commit=Search&search%5Bhide_empty%5D=yes&search%5Border%5D=count" target="_blank">List of Tags</a>
        <p>I am working on adding features for the website but if you have any suggestions feel
            free to reach out to me on the  <router-link to="/contact">Contact</router-link>    page. </p>
        
        <p>these are the current features being worked on : </p>
        <p>* adding live2d model viewer *</p>
        <p>* adding sorting on character page *</p>
        <p>* adding user account features *
            <br>**favoriting characters/upvoting** 
            <br>**comments on images**</p>
        <p>* adding more image sources *</p>
        <p>Thank you for visiting Nikke Viewer if you want to donate to keep the site ad free and to support the upkeep and 
            development of the website you can do so at my <a href="https://ko-fi.com/yureiryu" target="_blank">Ko-Fi</a> page.
        </p>
    </div>
</template>

<script>
export default {
    name: "AboutPage",
};
</script>

<style>
h1 {
    font-size: 2em;
    text-align: center;
    margin-top: 5vh;
    padding-top: 2vh;
}

p {
    text-align: center;
    padding: 0 2vw;
}

#wrapperAB {
    height: fit-content;
    width: 65vw;
    margin: 0 auto;
    margin-top: 5vh;
    background-color: #282A3A;
    border-radius: 15px;
    box-shadow: 0px 0px 75px #000000;
    padding-bottom: 2vh;
}

@media screen and (max-width: 767px) {
    p {
        text-align: center;
        padding: 0 20vw;
    }
    #wrapperAB {
    height: fit-content;
    width: 90vw;
    margin: 0 auto;
    margin-top: 5vh;
    background-color: #282A3A;
    border-radius: 15px;
}
}
</style>
